<template>
  <div class="withdraw-page">
    <el-page-header @back="goBack" content="提现" />
    <div class="card-list" v-if="userinfo">
      <div class="card-item">
        <p>
          可提现金额：<span class="num em">{{ income - hasWithdraw }}</span> ￥
        </p>
        <p class="tip">历史总收益：{{ income }}￥</p>
      </div>
      <div class="card-item">
        <p v-if="userinfo.alipayaccount">
          <span class="num em">{{ userinfo.alipayname }}</span
          >·{{ userinfo.alipayaccount }}
        </p>
        <p class="tip" v-if="userinfo.alipayaccount">收款账户</p>
        <p v-if="!userinfo.alipayaccount">尚未设置收款账户</p>
        <p class="tip">
          <span class="operation-item" @click="show = true">{{
            userinfo.alipayaccount ? "点击修改" : "点击设置"
          }}</span>
        </p>
      </div>
    </div>

    <div class="operation-wrap">
      <el-input placeholder="请输入内容" v-model="amount">
        <template slot="prepend">提现金额(￥)</template>
      </el-input>
      <van-button type="primary" @click="withdraw">申请提现</van-button>
    </div>

    <div class="intro">
      <p>1. 每次的提现额必须大于 10，并且为 5 的整数。</p>
      <p>2. 提现申请会在两周内进行处理。</p>
    </div>

    <div class="record-wrap">
      <h3>历史提现记录</h3>
      <van-empty
        v-if="records.length == 0 && !loading"
        description="暂无提现记录"
      />

      <div class="record-item header" v-if="records.length > 0">
        <span class="money">提现金额(￥)</span>
        <span class="time">提现时间</span>
        <span class="status">提现状态</span>
      </div>
      <div class="record-item" v-for="item in records" :key="item.id">
        <span class="money">{{ item.amount }}</span>
        <span class="time">{{ item.createtime | formatDate }}</span>
        <span class="status warning em" v-if="item.status == 'PENDING'"
          >审核中</span
        >
        <span class="status success em" v-if="item.status == 'DONE'"
          >已转账</span
        >
        <span class="status danger em" v-if="item.status == 'REJECTED'"
          >已拒绝:({{ item.reason }})</span
        >
      </div>
    </div>

    <van-dialog class="pay-dialog" v-model="show" :show-confirm-button="false">
      <h3 class="title">设置收款账户</h3>
      <p class="tip danger em">目前仅支持支付宝收款。</p>
      <div class="pay-wrap">
        <el-input
          v-model="alipayaccount"
          autocomplete="off"
          placeholder="可用于转账的支付宝账号"
        >
          <template slot="prepend" class="prepend">支付宝账号</template>
        </el-input>
        <el-input
          v-model="alipayname"
          autocomplete="off"
          placeholder="转账时姓名需要验证支付宝的真实姓名"
        >
          <template slot="prepend" class="prepend">真实姓名</template>
        </el-input>
        <div class="button-wrap">
          <van-button @click="show = false">取消</van-button>
          <van-button type="primary" @click="saveWithdrawAccount"
            >保存</van-button
          >
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { withdraw, getWithdraws, updateWithdrawAccount } from "../api/user";

export default {
  data() {
    return {
      show: false,
      amount: 0,
      income: 0,
      hasWithdraw: 0,
      alipayaccount: "",
      alipayname: "",
      loading: true,
      records: [],
    };
  },
  async created() {
    await this.refresh();
    this.alipayname = this.userinfo.alipayname;
    this.alipayaccount = this.userinfo.alipayaccount;
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async refresh() {
      const res = await getWithdraws();
      this.loading = false;
      if (res && res.code == 0) {
        this.records = res.data.records;
        this.income = res.data.income;
        this.hasWithdraw = res.data.hasWithdraw;
      }
    },

    async withdraw() {
      if (!this.userinfo.alipayaccount)
        return this.$toast.fail("请先补充支付宝账号信息");
      if (isNaN(this.amount) || this.amount < 10 || this.amount % 5 != 0) {
        return this.$toast.fail("提现金额不符合要求");
      }

      const res = await withdraw({ amount: this.amount });
      if (res && res.code == 0) {
        await this.updateUserinfo();
        await this.refresh();
        return this.$toast.success("提现申请已提交，等待审核");
      } else if (res.code == 10400) {
        return this.$toast.fail("剩余金额不足，无法提现");
      } else {
        return this.$toast.fail("参数错误");
      }
    },
    async saveWithdrawAccount() {
      const res = await updateWithdrawAccount({
        alipayaccount: this.alipayaccount,
        alipayname: this.alipayname,
      });
      if (res.code == 0) {
        this.show = false;
        await this.updateUserinfo();
        return this.$toast.success("收款账户设置成功");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.withdraw-page {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .card-item {
    width: 48%;
    padding: 40px 20px;
    background-color: #f8f8f8;
    border-radius: 4px;
    position: relative;
    text-align: center;
    margin-top: 20px;
    .num {
      font-size: 24px;
    }
    .tip {
      margin-top: 5px;
      display: flex;
      font-size: 13px;
      align-items: center;
      color: #999;
      justify-content: center;
      .van-icon {
        margin-left: 2px;
      }
      .operation-item {
        font-weight: bold;
        cursor: pointer;
        color: $theme-color;
        border-bottom: 1px dashed #f8f8f8;
        transition: ease 0.1s;
        &:hover {
          border-bottom: 1px dashed $theme-color;
        }
      }
    }
  }
}

.warn-tip {
  text-align: left;
  padding: 15px;
  margin-top: 20px;
  color: rgb(128, 82, 50);
  font-size: 13px;
  border-radius: 4px;
  background-color: #fffbe8;
  border: 1px solid rgba(73, 33, 5, 0.2);
  header {
    font-weight: bold;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 5px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.operation-wrap {
  margin-top: 30px;
  display: flex;
  .el-input {
    flex: auto;
  }
  .van-button {
    margin-left: 40px;
    height: 40px;
    width: 150px;
    flex-shrink: 0;
  }
}

.intro {
  padding: 20px;
  margin-top: 30px;
  color: rgb(128, 82, 50);
  font-size: 13px;
  border-radius: 4px;
  background-color: #fffbe8;
  border: 1px solid rgba(73, 33, 5, 0.2);
  p {
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.record-wrap {
  margin-top: 30px;
  h3 {
    text-align: center;
  }

  .record-item {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    &.header {
      font-weight: bold;
    }
    .money {
      width: 150px;
    }
    .time {
      flex: auto;
      text-align: center;
    }
    .status {
      width: 150px;
      text-align: right;
    }
  }
}

.pay-dialog {
  width: 460px;
  border-radius: 4px;
  top: 40%;
  h3 {
    font-size: 17px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .tip {
    text-align: center;
  }
  .pay-wrap {
    padding: 20px 30px;
    .label {
      font-size: 14px;
      font-weight: bold;
      padding-left: 10px;
      border-left: 4px solid $theme-color;
      line-height: 1;
      margin-bottom: 15px;
    }
    .el-input {
      margin-bottom: 15px;
    }
    .button-wrap {
      display: flex;
      justify-content: space-between;
      .van-button {
        width: 48%;
      }
    }
  }
}

@media (max-width: 700px) {
  .card-list {
    .card-item {
      padding: 20px 10px;
      margin-top: 10px;
      width: 100%;
    }
  }
  .operation-wrap {
    margin-top: 15px;
    .van-button {
      margin-left: 10px;
      width: 100px;
    }
  }
  .intro {
    margin-top: 15px;
  }
  .record-wrap {
    margin-top: 15px;
    .record-item {
      padding: 10px;
      font-size: 12px;
      .money {
        width: 100px;
      }
      .status {
        width: 100px;
      }
    }
  }
}
</style>
